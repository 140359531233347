body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.observablehq--inspect, .hidden {
  display: none !important;
}

:root{--syntax_normal:#1b1e23;--syntax_comment:#a9b0bc;--syntax_number:#20a5ba;--syntax_keyword:#c30771;--syntax_atom:#10a778;--syntax_string:#008ec4;--syntax_error:#ffbedc;--syntax_unknown_variable:#838383;--syntax_known_variable:#005f87;--syntax_matchbracket:#20bbfc;--syntax_key:#6636b4;--mono_fonts:82%/1.5 Menlo,Consolas,monospace}.observablehq--collapsed,.observablehq--expanded,.observablehq--function,.observablehq--gray,.observablehq--import,.observablehq--string:after,.observablehq--string:before{color:var(--syntax_normal)}.observablehq--collapsed,.observablehq--inspect a{cursor:pointer}.observablehq--field{text-indent:-1em;margin-left:1em}.observablehq--empty{color:var(--syntax_comment)}.observablehq--blue,.observablehq--keyword{color:#3182bd}.observablehq--forbidden,.observablehq--pink{color:#e377c2}.observablehq--orange{color:#e6550d}.observablehq--boolean,.observablehq--null,.observablehq--undefined{color:var(--syntax_atom)}.observablehq--bigint,.observablehq--date,.observablehq--green,.observablehq--number,.observablehq--regexp,.observablehq--symbol{color:var(--syntax_number)}.observablehq--index,.observablehq--key{color:var(--syntax_key)}.observablehq--prototype-key{color:#aaa}.observablehq--empty{font-style:oblique}.observablehq--purple,.observablehq--string{color:var(--syntax_string)}.observablehq--error,.observablehq--red{color:#e7040f}.observablehq--inspect{font:var(--mono_fonts);overflow-x:auto;display:block;white-space:pre}.observablehq--error .observablehq--inspect{word-break:break-all;white-space:pre-wrap}


@font-face {
  font-family: "Aller";
  src: local("Aller"), url("./assets/Aller_Rg.ttf") format("truetype");
}

* {
  font-family: "Aller" !important;
}
body {
  background-image: url("./assets/bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.header-logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.header-logo img {
  width: 30%;
}
footer {
  border-top: 1px solid #c6c6c6;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 1em;
}
footer *, footer a {
  color: #797979;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 2em;
  margin-bottom: .5em;
}
a {
  color: #6892b3;
  cursor: pointer;
}
a:hover {
  color: #86adcb;
}
p {
 z-index: -1;
}
p.bold {
  padding: 1em;
  border: 2px solid #d7c455;
  border-radius: 4px;
}
span.flexibility_color:not(:has(form)) {
  padding: 3px 5px;
  border: 1px solid #808080;
  border-radius: 5px;

  background-color: #d9d9d9;
}

/* HIGHLIGHTED SPANS */
/* span.wind_offshore_color, span.gas_color, span.demand_color, 
span.nuclear_color, span.elektrolysis_color, span.imports_color,
span.imports_renewable_color, span.brown_coal_color, span.flexibility_color,
span.hard_coal_color, span.wind_onshore_color, span.pv_color, */
span:is(
  .wind_offshore_color, 
  .gas_color, 
  .demand_color, 
  .nuclear_color, 
  .elektrolysis_color, 
  .imports_color, 
  .imports_renewable_color, 
  .brown_coal_color, 
  .flexibility_color, 
  .hard_coal_color, 
  .wind_onshore_color, 
  .pv_color), 
div:is(
    .wind_offshore_color, 
    .gas_color, 
    .demand_color, 
    .nuclear_color, 
    .elektrolysis_color, 
    .imports_color, 
    .imports_renewable_color, 
    .brown_coal_color, 
    .flexibility_color, 
    .hard_coal_color, 
    .wind_onshore_color, 
    .pv_color) form
{
  padding: 3px 5px;
  border: 1px solid #808080;
  border-radius: 5px;
}
span.wind_offshore_color, div.wind_offshore_color form {
  background-color: #8dd3c7;
}
span.gas_color, div.gas_color form {
  background-color: #ffffb3;
}
span.demand_color, div.demand_color form {
  background-color: #bebada;
}
span.nuclear_color, div.nuclear_color form {
  background-color: #fb8072;
}
span.elektrolysis_color, div.elektrolysis_color form {
  background-color: #80b1d3;
}
span.imports_color, div.imports_color form {
  background-color: #fdb462;
}
span.imports_renewable_color, div.imports_renewable_color form {
  background-color: #b3de69;
}
span.brown_coal_color, div.brown_coal_color form {
  background-color: #fccde5;
}
span.flexibility_color, div.flexibility_color form {
  background-color: #d9d9d9;
}
span.hard_coal_color, div.hard_coal_color form {
  background-color: #bc80bd;
}
span.wind_onshore_color, div.wind_onshore_color form {
  background-color: #ccebc5;
}
span.pv_color, div.pv_color form {
  background-color: #ffed6f;
}

.btn-outline-secondary {
  background-color: #D7C455;
  color: #fefefe;
}
.btn-outline-secondary:hover {
  background-color: #ad981c !important;
}
.nav-link {
  color: #D7C455;
}
.nav-link:hover {
  color: #ad981c;
}
#nav-tab {
  margin-top: 2em;
  margin-bottom: .5em;
}
#root{
  background-color: transparent;
} 
main.app {
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 3em 2em;
  background-color: #fafafa;
} 
.my-title {
  text-align: center;
  color: #81700f;
  font-size: 46px;
  font-weight: bold;
}
.row > div {
  margin: .5em 0;
}
label {
  word-wrap: break-word;
}
details {
  border: 1px solid #c6c6c6;
  border-left: 0;
  border-right: 0;
  margin-top: 1em;
  padding: 1em 0;
}
details + details {
  margin: 0;
  border-top: 0;
}

details + details {
  margin: 0;
  border-top: 0;
}

summary {
  color: #8A7705;
  cursor: pointer;
}
details[open] summary{
  margin-bottom: 1em;
}

details.small {
  border: 0;
  color: #4e4e4e;

  font-size: 90%;
  margin: 1em 0 ;
  padding: .5em 0;
}
details.small summary {
  color: #666666;
  font-size: 90%;
  cursor: pointer;
}
details.small[open] summary{
  margin-bottom: 1em;
}

.button {
	cursor: pointer;
	border: 1px solid #8A7705;
  height: 30px;
	background: none;
	box-shadow: none;
	border-radius: 5px; /*angles' curvature*/
	margin: 4px; /*free space around the button*/
  color: #8A7705;
  display: inline-block; /*buttons horizontally aligned*/
  text-align: center;
  padding-left: 5px; /*distance between button border and text*/
  padding-right: 5px;
}

.button:hover {
  background: #f8f3d3;
}

.annotated {
  position: relative;
  display: inline-flex;
  justify-content: center;

  background-color: pink !important;
  padding-left: 3px;
  padding-right: 3px;
}
.annotated::before {
  
  display: inline-flex;
  content: '';
  background-image: url("./assets/info.svg");
  background-size: cover;
  height: 20px;
  width: 20px;
}

.annotated:hover {
  background: #faf2c5;
}

.annotation {
  visibility: hidden;
  background-color: #f8f3d3;
  padding-left: 3px;
  padding-right: 3px;
  position: absolute;
  z-index: 1;
  line-height: 0.1;
  margin-left: 50px;
  /* min-width: (width/2.0)px; */
  visibility: hidden;
  border: solid 0.5px #666;
  box-shadow: 0 0 5px #ccc;
  padding: 5px;
  position: absolute;
  z-index: 1;
  line-height: 1;
bottom: 60%;
}

.annotated:hover .annotation {
  opacity: 1;
  visibility: visible;
}

/* RANGE BASE STYLE */
input[type=range] {
  -webkit-appearance: none;
  width: 200px;
  height: 3px;
  background: #c6c6c6;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent; 
  border-color: transparent;
  color: transparent;
}

/* RANGE SLIDER THUMB */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* border: 1px solid #000000; */
  height: 32px;
  width: 16px;
  border-radius: 50%;
  background: #d7c455;
  cursor: pointer;
  /* margin-top: -14px; You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
  /* border: 1px solid #000000; */
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #d7c455;
  cursor: pointer;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
  /* border: 1px solid #000000; */
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #d7c455;
  cursor: pointer;
}

input[type=number], input[type=text] {
  padding: 5px 7px;

  background: #fff;
  border: 1px solid #c6c6c6;
  border-radius: 3px;
} 
input[type=number]:focus-visible, input[type=text]:focus-visible {
  border: 1px solid #d7c455;
  /* box-shadow: 0; */
  outline: none;
}


/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: pink;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  /* z-index: auto; */
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* IMAGE AND GRAPHICS */
#heat_map, #heatmap div{
  width: 100% !important;
}

/* CHECKBOX STYLE */

input {
  accent-color: #D7C455;
}

div#core {
  background-color: #fafafa;
}

.section_first {
	scroll-margin-top: 230px;
}

.section_result {
	scroll-margin-top: 270px;
}

.section_result_wind_pv {
	scroll-margin-top: 230px;
}

.section_chapter {
	scroll-margin-top: 230px;
}

.section_last {
	scroll-margin-top: 295px;
}

.progress-bar {
  text-align: right;
  transition-duration: 3s;
}

.blinking{
  animation:blinkingText 1s infinite;
}
@keyframes blinkingText{
   50% {
  opacity: 0;
}
}